import { JSX } from "react";
import style from "ansi-styles";
import { SEGMENT_DATA } from "./segments";
import { Segment } from "../components/editable-item";

function getAnsiColorStart(hue: number) {
  // Must be in sync with color method.
  const styleFromLibrary = style.color.ansi256(hue);
  const colorNumber = styleFromLibrary.match(/\d+/)![0];
  return `\\[\\e[${colorNumber}m\\]`;
}

function getColorEnd() {
  return "\\[\\e[m\\]";
}

export function getConvertedPS1(segments: Segment[]) {
  let pre = "";
  let currentColor: number | null = null;

  const PS1 = segments.reduce((bashString, segment) => {
    const segmentData = SEGMENT_DATA[segment.id ?? "missing"] || {};

    if (segmentData.pre) {
      pre += segmentData.pre;
    }

    let newBashSubstring = "";

    if (!segment.customText && currentColor !== segment.color) {
      if (currentColor !== null) {
        newBashSubstring += getColorEnd();
      }

      currentColor = segment.color;

      newBashSubstring += getAnsiColorStart(segment.color);
    }

    newBashSubstring += segment.customText || segmentData.code;

    return bashString + newBashSubstring;
  }, 'export PS1="');

  const colorStop = currentColor === null ? "" : getColorEnd();

  return pre + "\n\n" + PS1 + colorStop + '"';
}

export function getPreview(segment: Segment) {
  const isSpace = segment.id === "space";

  let content: string | JSX.Element = segment.customText ?? "";
  if (isSpace) {
    content = <span>&nbsp;</span>;
  } else if (!content) {
    content = SEGMENT_DATA[segment.id ?? "missing"].example;
  }

  return content;
}
