import { getCircleAverage, getInfluencedHue } from "../utils/color";

function fillCanvasSquare(
  context: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: any,
  y2: any,
  hue: any,
) {
  context.beginPath();
  context.rect(x1, y1, x2, y2);
  context.fillStyle = `hsl(${hue}, 100%, 80%)`;
  context.fill();
}

export function initializeGrid(rowCount: number, columnCount: number) {
  const tempGrid = [];
  for (let rowIndex = 0; rowIndex < columnCount; rowIndex++) {
    const row = [];
    for (let columnIndex = 0; columnIndex < rowCount; columnIndex++) {
      const randomHue = Math.round(Math.random() * 359);

      row.push(randomHue);
    }
    tempGrid.push(row);
  }
  return tempGrid;
}

export function paintGrid(
  context: CanvasRenderingContext2D,
  grid: any[],
  squareSize: number,
) {
  let xPosition = 0;
  let yPosition = 0;

  grid.forEach((row) => {
    row.forEach((columnSquareHue: number) => {
      fillCanvasSquare(
        context,
        xPosition,
        yPosition,
        xPosition + squareSize,
        yPosition + squareSize,
        columnSquareHue,
      );

      yPosition += squareSize;
    });

    xPosition += squareSize;
    yPosition = 0;
  });
}

function getAdjacent(
  grid: number[][],
  rowIndex: number,
  columnIndex: number,
  rowMax: number,
  columnMax: number,
) {
  const adjacentSquares = [];
  if (rowIndex > 0 && columnIndex > 0) {
    adjacentSquares.push(grid[rowIndex - 1][columnIndex - 1]);
  }

  if (rowIndex > 0) {
    adjacentSquares.push(grid[rowIndex - 1][columnIndex]);
  }

  if (columnIndex > 0) {
    adjacentSquares.push(grid[rowIndex][columnIndex - 1]);
  }

  if (rowIndex < rowMax - 1 && columnIndex < columnMax - 1) {
    adjacentSquares.push(grid[rowIndex + 1][columnIndex + 1]);
  }

  if (rowIndex < rowMax - 1) {
    adjacentSquares.push(grid[rowIndex + 1][columnIndex]);
  }

  if (columnIndex < columnMax - 1) {
    adjacentSquares.push(grid[rowIndex][columnIndex + 1]);
  }

  if (rowIndex < rowMax - 1 && columnIndex > 0) {
    adjacentSquares.push(grid[rowIndex + 1][columnIndex - 1]);
  }

  if (columnIndex < columnMax - 1 && rowIndex > 0) {
    adjacentSquares.push(grid[rowIndex - 1][columnIndex + 1]);
  }

  return adjacentSquares;
}

export function getColorAdjustedGrid(grid: number[][], velocity: number) {
  let newGrid: number[][] = [];

  grid.forEach((row, rowIndex) => {
    newGrid.push([]);
    row.forEach((hue, columnIndex) => {
      const adjacentHues = getAdjacent(
        grid,
        rowIndex,
        columnIndex,
        grid.length,
        row.length,
      );
      const averageColor = getCircleAverage(adjacentHues.concat([hue]));
      const nextColorForSquare = getInfluencedHue(hue, averageColor, velocity);

      newGrid[rowIndex].push(nextColorForSquare);
    });
  });

  return newGrid;
}
