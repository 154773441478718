import { useWindowSize } from "@/utils/dom";
import "./styles.css";
import { Grid } from "@/features/grid";

export function Lavalamp() {
  const { width, height } = useWindowSize();

  const scaledWidth = Math.floor(width * 0.8);
  const scaledHeight = Math.floor(height * 0.6);

  return (
    <div className="lava-wrapper" style={{ height }}>
      <Grid
        width={scaledWidth}
        height={scaledHeight}
        velocity={8}
        squareSize={30}
      />
    </div>
  );
}
