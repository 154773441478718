import { StringLike } from "bun";
import { useFetchJson } from "../../../utils/fetch";

export interface RedditComment {
  data: {
    id: string;
    children: RedditComment[];
    body: string;
    author: string;
    replies: RedditComment;
  };
  kind: string;
}

export interface RedditPost {
  data: { id: string; title: string; selftext: string; url: string };
}

export function useSubredditData({ subreddit }: { subreddit: string }) {
  return useFetchJson(
    `https://www.reddit.com/r/${subreddit}/hot.json`,
    !!subreddit,
  );
}
export function useCommentData({
  subreddit,
  post,
}: {
  subreddit: string;
  post: RedditPost;
}): null | RedditComment[] {
  let postId = post && post.data && post.data.id;
  if (!postId) {
    return null;
  }

  const fetchedData = useFetchJson(
    `https://www.reddit.com/r/${subreddit}/comments/${postId}.json`,
    !!postId,
  );

  if (!fetchedData) {
    return null;
  }

  return fetchedData[1].data.children;
}
