export function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function getRandomInsideBounds(
  minPosition: number,
  maxPosition: number,
  rawOptions = {},
) {
  const opts = {
    minSize: 0,
    ...rawOptions,
  };

  const position = randomInRange(minPosition, maxPosition - opts.minSize);
  const size = randomInRange(position + opts.minSize, maxPosition) - position;

  return [position, size];
}

export function mateRandomInsideBounds(
  maxPosition: number,
  position1: number,
  position2: number,
  size1: number,
  size2: number,
) {
  const position = randomChoice(position1, position2);
  const size = Math.min(randomChoice(size1, size2), maxPosition - position);

  return [position, size];
}

export function randomChoice(...args: number[]) {
  const numberOfChoices = args.length;
  const randomChoiceIndex = Math.floor(Math.random() * numberOfChoices);
  return args[randomChoiceIndex];
}
