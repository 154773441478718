import React, { useRef, useLayoutEffect } from "react";
import {
  MarginBottom,
  LittleLine,
  EditableItemBottom,
  EditableItemBody,
  EditableItemRight,
  EditableItemLeft,
  EditableItemClose,
  EditableItemTop,
  EditableItemWrapper,
  VerticalCenter,
} from "./styles";
import { AddOne } from "../add-one";
import { ColorPicker } from "../color-picker";
import {
  ACTION_ADD,
  ACTION_REMOVE,
  ACTION_MOVE,
  ACTION_SET_COLOR,
  ACTION_SET_COLOR_PICKER_OPEN,
  ACTION_SET_SEGMENT_PICKER_INDEX,
  ACTION_SET_SEGMENT_DRAGGING_INDEX,
} from "../../reducer";
import { getPreview } from "../../utils/transform";

export interface Segment {
  id?: string | null;
  colorPickerOpen?: boolean;
  color: number;
  customText?: string;
}

interface EditableProps {
  segment: Segment;
  dispatch: React.Dispatch<any>;
  index: number;
  isBeingGrabbed: boolean;
  hideAdd: boolean;
  reportReference?: (index: number, reference: HTMLDivElement) => void;
  isClosestIndex: boolean;
}

export function Editable({
  segment,
  dispatch,
  index,
  isBeingGrabbed,
  hideAdd,
  reportReference,
  isClosestIndex,
}: EditableProps) {
  const name = segment.id === "space" ? "[space]" : getPreview(segment);
  const wrapperElementRef = useRef(null);

  const { colorPickerOpen, color } = segment;

  function onAddClick() {
    dispatch({ type: ACTION_ADD, index: index + 1 });
  }
  function onRemoveClick() {
    dispatch({ type: ACTION_REMOVE, index });
  }

  function onMoveLeft() {
    dispatch({ type: ACTION_MOVE, fromIndex: index, toIndex: index - 1 });
  }

  function onMoveRight() {
    dispatch({ type: ACTION_MOVE, fromIndex: index, toIndex: index + 1 });
  }

  function setItemColor(hue: number) {
    dispatch({ type: ACTION_SET_COLOR, index, color: hue });
  }

  function setColorPickerOpen(isOpen: boolean) {
    dispatch({ type: ACTION_SET_COLOR_PICKER_OPEN, index, isOpen });
  }

  function openSegmentPicker() {
    dispatch({ type: ACTION_SET_SEGMENT_PICKER_INDEX, index });
  }

  useLayoutEffect(() => {
    if (reportReference && wrapperElementRef.current) {
      reportReference(index, wrapperElementRef.current);
    }
  });

  return (
    <MarginBottom>
      <VerticalCenter>
        <EditableItemWrapper
          isBeingGrabbed={isBeingGrabbed}
          ref={wrapperElementRef}
        >
          <EditableItemTop>
            <EditableItemClose onClick={onRemoveClick}>✖</EditableItemClose>
            <EditableItemLeft onClick={onMoveLeft}>←</EditableItemLeft>
            <EditableItemRight onClick={onMoveRight}>→</EditableItemRight>
            <ColorPicker
              color={color}
              onColorSelection={setItemColor}
              setIsOpen={setColorPickerOpen}
              isOpen={colorPickerOpen ?? false}
            />
          </EditableItemTop>
          <EditableItemBody onClick={openSegmentPicker}>
            {name}
          </EditableItemBody>
          <EditableItemBottom
            onMouseDown={() =>
              dispatch({ type: ACTION_SET_SEGMENT_DRAGGING_INDEX, index })
            }
          >
            <LittleLine />
            <LittleLine />
          </EditableItemBottom>
        </EditableItemWrapper>
        <AddOne hide={hideAdd && !isClosestIndex} onClick={onAddClick} />
      </VerticalCenter>
    </MarginBottom>
  );
}
