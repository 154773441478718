import "./styles.css";

function getRandom(max: number) {
  return Math.random() * max;
}

let starsCache: Record<string, string> = {};
function addStars(width: number, height: number) {
  const cacheKey = `${width}${height}`;

  if (starsCache[cacheKey]) {
    return starsCache[cacheKey];
  }

  let boxShadowValue = "";
  boxShadowValue += `${getRandom(width)}px ${getRandom(height)}px white`;
  for (let i = 2; i < width; i++) {
    boxShadowValue += ` , ${getRandom(width)}px ${getRandom(height)}px white`;
  }

  starsCache[cacheKey] = boxShadowValue;

  return boxShadowValue;
}

export function Stars(props: { width: number; height: number }) {
  return (
    <div
      className="stars"
      style={{ boxShadow: addStars(props.width * 2, props.height * 2) }}
    />
  );
}
