import React from "react";
import { AddOneWrapper, AddOneInner } from "./styles";

export function AddOne({
  onClick,
  hide,
}: {
  onClick: () => void;
  hide?: boolean;
}) {
  return (
    <AddOneWrapper hide={hide ?? false} onClick={onClick}>
      <AddOneInner>＋</AddOneInner>
    </AddOneWrapper>
  );
}
