import { useEffect } from "react";
import { Homepage } from "../pages/homepage";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { Projects } from "../pages/projects";
import { Work } from "../pages/work";
import "./style.css";
import { Play } from "@/pages/play";

export function App() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/play">
          <Route index element={<Play />} />
          <Route path="*" element={<Projects />} />
        </Route>
        <Route path="/work" element={<Work />} />
        <Route path="/" element={<Homepage />} />
      </Routes>
    </ScrollToTop>
  );
}

function ScrollToTop({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Only scroll when the pathname changes

  return <>{children}</>;
}
