import { useEffect, useReducer } from "react";

const ACTION_REQUESTED = "ACTION_REQUESTED";
const ACTION_FETCHED = "ACTION_FETCHED";

interface FetchState {
  response: any | null;
  url: string | null;
}

interface FetchAction {
  type: string;
  url: string;
  response?: any;
}

export function fetchReducer(
  state: FetchState,
  action: FetchAction,
): FetchState {
  switch (action.type) {
    case ACTION_REQUESTED: {
      return { ...state, url: action.url, response: null };
    }
    case ACTION_FETCHED: {
      console.log(action.url, action.response);
      return { ...state, url: action.url, response: action.response };
    }
    default:
      return state;
  }
}

const INITIAL_FETCH_STATE: FetchState = {
  response: null,
  url: null,
};

export function useFetchJson(
  requestedUrl: string,
  ready: boolean = true,
): any | null {
  const [{ response, url }, dispatch] = useReducer(
    fetchReducer,
    INITIAL_FETCH_STATE,
  );

  useEffect(() => {
    if (!ready) {
      return;
    }

    if (requestedUrl !== url) {
      dispatch({ type: ACTION_REQUESTED, url: requestedUrl });

      fetchUrl(requestedUrl, (data: any) => {
        dispatch({ type: ACTION_FETCHED, url: requestedUrl, response: data });
      });
    }
  }, [requestedUrl, ready, url]);

  return response;
}

function fetchUrl(url: string, successCallback: (data: any) => void): void {
  fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((jsonData) => {
      successCallback(jsonData);
    })
    .catch((error) => {
      throw new Error(error);
    });
}
