import { Routes, Route } from "react-router-dom";
import { CurrentTime } from "./components/current-time";
import { FlightFilms } from "./components/flightfilms";
import { CTA } from "./components/cta";
import { Rhymes } from "./components/rhymes";
import { Lavalamp } from "./components/lavalamp";
import { MusicSequence } from "./components/music";
import { PS1Gen } from "@/features/ps1";
import { Decode } from "@/features/decode";
import { Reddit } from "@/features/reddit";
import { StoryTime } from "@/features/storytime";
import { Mating } from "./components/mating/component";
import { JeoPlayAlong } from "./components/jeoplayalong";

export function Projects() {
  return (
    <Routes>
      <Route path="current-time" element={<CurrentTime />} />
      <Route path="cta" element={<CTA />} />
      <Route path="rhymes" element={<Rhymes />} />
      <Route path="lava" element={<Lavalamp />} />
      <Route path="creatures" element={<Mating />} />
      <Route path="sequence" element={<MusicSequence />} />
      <Route path="prompt" element={<PS1Gen />} />
      <Route path="story" element={<StoryTime />} />
      <Route path="reddit" element={<Reddit />} />
      <Route path="flight" element={<FlightFilms />} />
      <Route path="decode" element={<Decode />} />
      <Route path="jeo-play-along" element={<JeoPlayAlong />} />
    </Routes>
  );
}
