export const SheetMusic = {
  Zelda: {
    sequences: [
      [false, false, true, false, false, false, false, false, false],
      [false, false, false, true, false, false, false, false, false],
      [false, false, false, false, true, false, false, false, false],
      [false, false, false, false, false, true, true, true, true],
      [false, false, true, false, false, false, false, false, false],
      [false, false, false, true, false, false, false, false, false],
      [false, false, false, false, true, false, false, false, false],
      [false, false, false, false, false, true, true, true, true],
      [false, false, true, false, false, false, false, false, false],
      [false, false, false, true, false, false, false, false, false],
      [false, false, false, false, true, false, false, false, false],
      [false, false, false, false, false, true, true, true, true],
    ],
    sequenceNotes: [
      "A3 q",
      "A#3 q",
      "B3 q",
      "C4 q",
      "C3 q",
      "C#3 q",
      "D3 q",
      "Eb3 q",
      "F2 q",
      "F#2 q",
      "G2 q",
      "Ab3 q",
    ],
  },
};
