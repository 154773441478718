import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  MutableRefObject,
} from "react";

interface FocusOnLoadOptions {
  select?: boolean;
}

interface FocusOnLoadOptions {
  select?: boolean;
}

interface UseFocusOnLoadReturn {
  ref: MutableRefObject<HTMLInputElement | null>;
  refFocus: () => void;
}

export function useFocusOnLoad({
  select,
}: FocusOnLoadOptions = {}): UseFocusOnLoadReturn {
  const ref = useRef<HTMLInputElement | null>(null);
  const hasSelected = useRef<boolean>(false);

  function refFocus(): void {
    if (ref && ref.current && ref.current.focus) {
      ref.current.focus();
      if (select && !hasSelected.current) {
        hasSelected.current = true;
        ref.current.select();
      }
    }
  }

  useEffect(refFocus, [select]);

  return { ref, refFocus };
}
export function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function updateWindowSize() {
    if (width !== window.innerWidth || height !== window.innerHeight) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  });

  return { width, height };
}
