import {
  ChangeEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./style.css";
// @ts-ignore
import alexHappyPNG from "./images/alexhappy.png";
// @ts-ignore
import alexWaitingPNG from "./images/alexwaiting.png";
// @ts-ignore
import alexUpsetPNG from "./images/alexupset.png";

function cstl(...args: string[]) {
  return args.join(" ");
}

function tileValue(index: number, round: number): number {
  return Math.ceil(index + 1) * 100 * round;
}

function numberWithCommas(x: number) {
  const isNeg = x < 0;
  if (isNeg) {
    return x;
  }
  return x.toLocaleString();
}

const OPTIONS_COUNT = 5;

export function JeoPlayAlong() {
  const [score, setScore] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [round, _setRound] = useState(2);
  const [dailyBetStr, setDailyBet] = useState<string>("");
  const [isDaily, setIsDaily] = useState(false);
  const [settingsAuto, setAuto] = useState(true);

  function setRound(updateFunc: SetStateAction<number>) {
    setSelected(0);
    _setRound(updateFunc);
  }

  function parsedDaily() {
    const parsed = parseInt(dailyBetStr, 10);
    if (!isNaN(parsed)) {
      return parsed;
    } else {
      return 0;
    }
  }

  function markDone(autoIncrement = false) {
    if (settingsAuto && autoIncrement) {
      setSelected((prevSel: number | null) => {
        if (prevSel === null) {
          return 0;
        }
        const newSel = prevSel + 1;

        if (newSel >= OPTIONS_COUNT) {
          return 0;
        }

        return newSel;
      });
    } else {
      setSelected(null);
    }

    setIsDaily(false);
    setDailyBet("");
    window.scrollTo(0, 0);
  }

  // Server side rendering for no reason is fun!
  useEffect(() => {
    setScore(parseInt(localStorage.getItem("score") ?? "0", 10));
    setRound(parseInt(localStorage.getItem("round") ?? "2", 10));
    console.log(localStorage.getItem("auto"));
    setAuto(localStorage.getItem("auto") !== "false");
  }, []);

  function persistScore(scoreChange: number) {
    setScore((prev: number) => {
      const newScore = scoreChange + prev;
      localStorage.setItem("score", newScore.toString());
      localStorage.setItem("round", round.toString());
      return newScore;
    });
  }

  return (
    <div className="jeo-container h-screen flex flex-col">
      {/* <Head>
        <title>JeoPlayAlong</title>
        <meta
          name="description"
          content="Play along with your favorite show!"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head> */}
      <div className="flex-0">
        <div className="flex items-center justify-center full-width">
          <button
            className="no-style-button buttstop"
            onClick={() => {
              setRound((prev) => Math.max(prev - 1, 1));
            }}
          >
            prev round
          </button>
          <h1 className="title">JeoPlayAlong</h1>
          <button
            className="no-style-button buttstop"
            onClick={() => {
              setRound((prev) => prev + 1);
            }}
          >
            next round
          </button>
        </div>
        <div className="flex justify-center items-center mx-4">
          <Alex score={score} reverse={false} />
          <button
            className={cstl(
              "no-style-button score",
              score >= 0 ? "scorepos" : "scoreneg",
            )}
            onClick={() => {
              setShowSettings((prev) => !prev);
            }}
          >
            {score >= 0 ? `$${numberWithCommas(score)}` : `-$${score * -1}`}
          </button>
          <Alex score={score} reverse={true} />
        </div>
        {showSettings && (
          <div className="flex flex-col justify-center items-center mx-4 bg-gray-800 py-4">
            <h2 className="mb-2 font-extrabold settings-title">Settings</h2>
            <Toggle
              name="Auto-Increment"
              on={settingsAuto}
              description="After clicking ✅ or ❌, automatically select the next question amount (e.g. 200 -> 400). Automates a common Jeopardy pattern."
              onToggle={() => {
                setAuto((prev) => {
                  const newAuto = !prev;
                  localStorage.setItem("auto", newAuto.toString());
                  return newAuto;
                });
              }}
            />
            <div className="flex w-11/12 mb-2">
              <button
                className="no-style-button double-clear flex items-center border rounded px-2 py-1 border-red-600 text-red-600 mr-2 grow"
                onDoubleClick={() => {
                  setScore(0);
                }}
              >
                🚫 Click x2 to clear score
              </button>
              <button
                className="no-style-button flex items-center border rounded close-cont px-2 py-1 border-white text-white"
                onClick={() => {
                  setShowSettings(false);
                }}
              >
                <svg
                  className="mr-2"
                  fill="white"
                  height="15px"
                  width="15px"
                  version="1.1"
                  viewBox="0 0 460.775 460.775"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path>
                  </g>
                </svg>
                <div className="close-me">Close</div>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 justify-start flex-col items-center m-4">
        <div className="board">
          {Array.from(Array(OPTIONS_COUNT), (_, i) => (
            <div key={i} className="flex">
              <button
                className={cstl(
                  "no-style-button buttsmark",
                  selected === i ? "" : "opacity-0 pointer-events-none",
                )}
                onClick={() => {
                  persistScore(-tileValue(selected!, round));
                  markDone(true);
                }}
              >
                ❌
              </button>
              <button
                key={i}
                onClick={() => {
                  setIsDaily(false);
                  setShowSettings(false);
                  selected === i ? setSelected(null) : setSelected(i);
                }}
                className={cstl(
                  "no-style-button select",
                  selected === i ? "selected" : "",
                )}
              >
                <div className={cstl("flex justify-center items-center")}>
                  <div className="dolla">$</div>
                  {tileValue(i, round)}
                </div>
              </button>
              <button
                className={cstl(
                  "no-style-button buttsmark",
                  selected === i ? "" : "opacity-0 pointer-events-none",
                )}
                onClick={() => {
                  persistScore(tileValue(selected!, round));
                  markDone(true);
                }}
              >
                ✅
              </button>
            </div>
          ))}
          <div key="dd" className="flex items-center justify-center">
            <button
              className={cstl(
                "no-style-button buttsmark",
                isDaily ? "" : "opacity-0 pointer-events-none",
              )}
              onClick={() => {
                persistScore(-parsedDaily());
                markDone();
              }}
            >
              ❌
            </button>
            <button
              onClick={() => {
                setSelected(null);
                setIsDaily((prev) => !prev);
              }}
              className={cstl(
                "no-style-button select",
                isDaily ? "selected" : "",
              )}
            >
              <div className="double">
                Daily <br />
                Double
              </div>
            </button>
            <button
              className={cstl(
                "no-style-button buttsmark",
                isDaily ? "" : "opacity-0 pointer-events-none",
              )}
              onClick={() => {
                persistScore(parsedDaily());
                markDone();
              }}
            >
              ✅
            </button>
          </div>
        </div>

        {isDaily ? (
          <div>
            <div>
              <input
                className="daily"
                onChange={(evt) => {
                  setDailyBet(evt.target.value);
                }}
                value={dailyBetStr}
                type="number"
                pattern="\d*"
              />
            </div>
            <div
              className={cstl(
                "scoresmall",
                score >= 0 ? "scorepos" : "scoreneg",
              )}
            >
              psst:{" "}
              {score >= 0 ? `$${numberWithCommas(score)}` : `-$${score * -1}`}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Toggle({
  name,
  on,
  description,
  onToggle,
}: {
  name: string;
  on: boolean;
  description: string;
  onToggle: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div className="jeo-increment border rounded p-2 w-11/12 mb-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          onChange={onToggle}
          checked={on}
          className="sr-only peer"
        />
        <span className="ml-3 text-m font-medium text-gray-900 dark:text-gray-300">
          {name}
        </span>
      </label>
      <p className="text-xs text-gray-400">{description}</p>
    </div>
  );
}

const faces = {
  happy: (
    <img
      className="rounded-full mx-2"
      src={alexHappyPNG}
      alt="Alex happy"
      width={50}
      height={50}
    />
  ),
  waiting: (
    <img
      className={`rounded-full mx-2 ${"rev"}`}
      src={alexWaitingPNG}
      alt="Alex waiting"
      width={50}
      height={50}
    />
  ),
  upset: (
    <img
      className="rounded-full mx-2"
      src={alexUpsetPNG}
      alt="Alex upset"
      width={50}
      height={50}
    />
  ),
} as const;

const revFaces = {
  happy: (
    <img
      className={`rounded-full mx-2 ${"rev"}`}
      src={alexHappyPNG}
      alt="Alex happy"
      width={50}
      height={50}
    />
  ),
  waiting: (
    <img
      className="rounded-full mx-2"
      src={alexWaitingPNG}
      alt="Alex waiting"
      width={50}
      height={50}
    />
  ),
  upset: (
    <img
      className={`rounded-full mx-2 ${"rev"}`}
      src={alexUpsetPNG}
      alt="Alex upset"
      width={50}
      height={50}
    />
  ),
} as const;

/**
 * When the score goes up, he's happy, when the score goes down, he's sad
 * He is otherwise neutral
 */
function Alex({ score, reverse }: { score: number; reverse: boolean }) {
  const lastScore = useRef(score);
  const [face, setFace] = useState<"happy" | "waiting" | "upset">("waiting");

  useEffect(() => {
    if (score > lastScore.current) {
      setFace("happy");
    } else if (score < lastScore.current) {
      setFace("upset");
    }
    lastScore.current = score;
  }, [score]);

  useEffect(() => {
    if (face !== "waiting") {
      const id = setTimeout(() => {
        setFace("waiting");
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [face, score]);

  return (reverse ? revFaces : faces)[face];
}
